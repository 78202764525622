// Helpers
// MDB helpers
.img-fluid,
.video-fluid {
  max-width: 100%;
  height: auto;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  p {
    margin: 0;
  }
  ul {
    text-align: center;
    li {
      margin-bottom: $flex-center-ul-mb;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.hr-light {
  border-top: 1px solid $hr-light;
}

.hr-dark {
  border-top: 1px solid $hr-dark;
}

// Responsive width
.w-responsive {
  width: 75%;
  @media (max-width: 740px) {
    width: 100%;
  }
}

// Collapsible body
.collapsible-body {
  display: none;
}

.jumbotron {
  box-shadow: $z-depth-1;
  border-radius: $border-radius-base;
  background-color: $white-base;
}

@each $name, $color in $basic-mdb-colors {
  @include bg-variant(".bg-#{$name}", $color);
  .border-#{$name} {
    border-color: $color !important;
  }
}

.card-img-100 {
  width: 100px;
  height: 100px;
}
.card-img-64 {
  width: 64px;
  height: 64px;
}

.mml-1 {
  margin-left: - 0.25rem !important;
}

.flex-1 {
  flex: 1;
}


