// Dropdowns
.dropdown {
  .dropdown-menu {
    .dropdown-item {
      &:active {
        color: #000000;
        text-decoration: underline;
      }
    }
  }
}
