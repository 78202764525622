// Your custom styles
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, and, address, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, dl, dt, dd, ol, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, input, textarea, select {
	background:transparent;
	border:0;
	margin:0;
	outline:0;
	padding:0;
}
div{
    box-sizing: border-box;
}

.navbar .nav-item a:hover.nav-link {
    color:$secondary-color;
}


html,
body {
    height: 100%;


}

section{
    overflow-x: hidden;
}

//ESTILOS GENERALES

header{
    overflow: hidden; 
    z-index: 2;
    height: 69px;
    width: 100%;
    position: fixed;
}

.logo_maraton_zaragoza{
    background: url(../img/logo_maraton.svg) no-repeat center center;
    display: block;
    width: 418px;
    height: 56px;
}
.margen_logo{
    margin-right: 100px;
}
ul{
    margin:0;
    padding: 0;
    list-style: none;
}
ul.corredor{
    margin:0;
    padding: 0;
    list-style: disc;
}
ul.lista{
    margin:0;
    margin-left: 25px;
    padding: 0;
    list-style: disc;
}
ul.lista li{
padding-bottom: 6px;
}
ul.reglamento{
    margin:0;
    padding: 0;
    list-style: disc;
    margin-left: 30px;
}
#reglamento p{
    margin-bottom: 12px;
}

ul.corredor li, ul.reglamento li{
    font-size: 14px;
    padding: 5px 0px;
    color:#000000;
}
body, p, span{
    color:#414141;
    line-height: 1.5rem;
}

section{
    padding: 6rem 4rem;
}
.ir-arriba {
    display: none;;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000000;
}
a.link{
    color:$secondary-color;
    text-decoration: underline;
}
//HEADER

.navbar {
    z-index: 2;
    padding: 0rem;
}
.navbar-expand-lg .navbar-nav .nav-item {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0rem;
    padding-left: 0rem;
    color:#363636;
    padding: 1rem 0;
}
.navbar-expand-lg .navbar-nav .nav-link.active {
    color: $secondary-color;
}


.TopBar .menuNav span.label {
    display: none;}

.TopBar {
        display: none;
}


//SUBMENU
#verde{
    display: none;
    background-color: $secondary-color;
     height:45.59px ;
     transition: background-color .25s, opacity .25s ease-in-out;
     width: 100%;
     position: fixed;
     top:56px;
     z-index: 1;
}


.triangulo{
    position: absolute;
    top:-8px;
    left:0;
    background: url(../img/triangulo_menu.png) no-repeat top left;
    height: 8px;
    width: 16px;
}

&.has-children { 
    &, .nav-item {
      position: relative;
    }
  }
  .navbar .navbar-nav .sub-menu {
    position: absolute;
    z-index: 3000;
    top: 100%;
    left: 1.75rem;
    border-width: 0;
    display: flex;
    width: 100vw;
    padding:0.8rem 0;

}
.navbar .sub-menu {
     border-width: 1px 0 0;
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    transition: visibility .25s, opacity .25s ease-in-out;
 }
.navbar .sub-menu{
    background-color: $secondary-color;
}
.navbar .sub-menu li{
    line-height: 0.9rem;
}
.navbar .sub-menu li::after{
    content: 'l';
    padding-left: 6px;
    padding-right: 6px;
    color:#ffffff;
    margin: 0 !important;
    padding-top: 0 !important;
    line-height: 0 !important;
}

  .nav-item {
    &:hover,
    &:active,
    &:focus {
      > .sub-menu {
        opacity: 1;
        height: auto;
        width: 100vw;
        &,
        .menu-item {
          visibility: visible;
        }
      }
    }
}  

@media screen and (max-width:992px) {
    
.TopBar .menuNav #nav-icon {
    width: 30px;
    height: 27px;
    position: relative;
    -webkit-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
}
.TopBar .menuNav #nav-icon, .TopBar .menuNav #nav-icon span {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
.TopBar .menuNav #nav-icon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 75%;
    background: $secondary-color;
    opacity: 1;
    -webkit-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
.TopBar .menuNav #nav-icon span.blanco {
    display: block;
    position: absolute;
    height: 4px;
    width: 25%;
    background: $primary-color;

}

.scroll-menu.TopBar .menuNav #nav-icon span {
    background: $secondary-color;
}

.TopBar .menuNav #nav-icon span:nth-child(odd) {
    right: 0;
    border-radius: 4px 0 0 4px;
}
.TopBar .menuNav #nav-icon span:first-child, .TopBar .menuNav #nav-icon span:nth-child(2) {
    top: 0;
}
.TopBar .menuNav #nav-icon span:nth-child(3), .TopBar .menuNav #nav-icon span:nth-child(4) {
    top: 8px;
}
.TopBar .menuNav #nav-icon span:nth-child(5), .TopBar .menuNav #nav-icon span:nth-child(6) {
    top: 16px;
}
.TopBar .menuNav #nav-icon.open span:first-child, .TopBar .menuNav #nav-icon.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.TopBar .menuNav #nav-icon.open span:nth-child(2), .TopBar .menuNav #nav-icon.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.TopBar .menuNav #nav-icon.open span:first-child {
    left: 2.5px;
    top: 3.5px;
}
.TopBar .menuNav #nav-icon.open span:nth-child(2) {
    left: calc(50% - 2.5px);
    top: 3.5px;
}
.TopBar .menuNav #nav-icon.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
}
.TopBar .menuNav #nav-icon.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
}
.TopBar .menuNav #nav-icon.open span:nth-child(5) {
    left: 2.5px;
    top: 14.5px;
}
.TopBar .menuNav #nav-icon.open span:nth-child(6) {
    left: calc(50% - 2.5px);
    top: 14.5px;
}
.scroll-menu.TopBar .menuNav #nav-icon.open span:first-child {
    background: $white;
}
.scroll-menu.TopBar .menuNav #nav-icon.open span:nth-child(2) {
    background: $white;
}
.scroll-menu.TopBar .menuNav #nav-icon.open span:nth-child(5) {
    background: $white;
}
.scroll-menu.TopBar .menuNav #nav-icon.open span:nth-child(6) {
    background: $white;
}
.TopBar .bigMenu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 66px;
    left: 0;
    background-color:$primary-color;
    z-index: 900;
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: -webkit-transform .5s ease-in-out;
    -o-transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    -webkit-transform: translateX(100vw);
    -ms-transform: translateX(100vw);
    transform: translateX(100vw);
    color: $black;
}
.TopBar .bigMenu.open {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.TopBar .bigMenu ul {
    max-width: 98%;
    margin: 0;
    list-style: none;
    line-height: 1em;
    padding: 0;
}
.TopBar .bigMenu .navbar-nav .nav-item{
    cursor: pointer;
    margin: 0px;
    padding: 15px 30px;
    border-bottom: 1px solid #ddcb02;
}
.sub-menu li{
    cursor: pointer;
    margin: 0px;
    padding: 15px 30px;
    border-bottom: none !important;
}
.sub-menu li a{
    font-size: 0.9rem;
}
.TopBar .bigMenu ul li a{
    color: #363636;
}
.TopBar .bigMenu ul li a:hover{
    color: $secondary-color;
}

.TopBar .bigMenu .dropdown .dropdown-menu .dropdown-item{
    padding: 1rem 0.5rem;
    margin-left: 0;
    font-size: 1rem;
    text-align: left;
}
.TopBar .bigMenu .dropdown-menu.show {
    display: table;
    width: 96%;
}

    .contiene-logo-menu{
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 68px;
    width: 100%;  
    padding: 10px 4%;
    }
    .TopBar {
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
  
        z-index: 900;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background:$primary-color;
    -webkit-box-shadow: 0 5px 6px -6px rgba(109, 109, 109, 0.3);
    -moz-box-shadow: 0 5px 6px -6px rgba(109, 109, 109, 0.3);
    box-shadow: 0 5px 6px -6px rgba(109, 109, 109, 0.7);
    font-weight: 600;
}
.TopBar .holder {
    margin: auto;
    display: block;
    text-decoration: none;
    color: inherit;
}


.TopBar .menuNav {
    position: relative;
    color: $white;
    font-size: 16px;
    letter-spacing: .5px;
    cursor: pointer;
    z-index: 901;
    padding: 0px 10px 0px 0px;
    display: table;
}
.navbar a{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color:#363636;
}
.TopBar .menuNav span.label {
    opacity: 1;
    -webkit-transition: opacity .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
}
.TopBar .menuNav span.label.hide {
    opacity: 0;
}
    
.navbar{
        display: none !important;
    }
    .TopBar{
        display: block;
    }
    
    .inicio{
        margin-top: 68px;
    }

}
/********ANIMACION MENU*******/

.hvr-underline-from-center {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
}
.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 10px;
  background: $secondary-color;
  height: 2px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-from-center:hover:before, .hvr-underline-from-center:focus:before, .hvr-underline-from-center:active:before, .hvr-underline-from-center.active:before  {
  left: 0;
  right: 0;
}
/********FIN ANIMACION MENU*******/



@media screen and (max-width:360px){


    .navbar-nav .nav-link {
        padding-right: 0;
        padding-left: 0;
        font-size: 1.1rem;
    }
}

@media screen and (max-width:340px){
    
    
    .contiene-logo-menu{
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;  
    padding: 10px 4%;
    }
    .logo_maraton_zaragoza{
    display: block;
    width: 150px;
    height: 34px;
    }
    .TopBar .bigMenu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 100px;
    left: 0;
    background-color: white;
    z-index: 900;
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: -webkit-transform .5s ease-in-out;
    -o-transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out, -webkit-transform .5s ease-in-out;
    -webkit-transform: translateX(100vw);
    -ms-transform: translateX(100vw);
    transform: translateX(100vw);
    color: $black;
    }
    
   
}

//FIN HEADER


// FOOTER
.hoka{
    width: 120px;
    height: auto;
}
.quiron{
    width: 135px;
    height: auto;
}
.hyunday{
    width: 100px;
    height: auto;
}
.sanytol{
width: 140px;
height: auto;
}
.goya{
    width: 90px;
    height: auto;
}
.policia{
    width: 105px;
    height: auto;
}
.tootemi{
    width: 75px;
    height: auto;
}
.runpro{
    width: 80px;
    height: auto;
}
.titulo_patrocinadores{
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 5px;
    margin-bottom:15px;
    width: 75%;
    text-align: center;
}

footer{
    background: #f6f6f6;
    padding: 2px;
}
.btn-floating {
    width: 35px;
    height: 35px;
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    margin: 10px;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    border: 1px solid #067233;
}
.fb {
    background: url(../img/fb_verde.svg) no-repeat center center;
    width: 32px;
    height: 18px;
    display: block;
    padding: 2px 12px;
    margin-top: 7px;
  }
  
  a:hover .fb {
    background: url(../img/fb_blanco.svg) no-repeat center center;;
  }
  
  .tw {
    background: url(../img/tw_verde.svg) no-repeat center center;
    width: 33px;
    height: 15px;
    display: block;
    padding: 2px 12px;
    margin-top: 10px;
  }
  
  a:hover .tw {
    background: url(../img/tw_blanco.svg) no-repeat center center;
  }
  
  .inst {
    background: url(../img/inst_verde.svg) no-repeat center center;
    width: 31px;
    height: 16px;
    display: block;
    padding: 2px 11px;
    margin-top: 9px;
  }
  
  a:hover .inst {
    background: url(../img/inst_blanco.svg) no-repeat center center;
  }
  
  .caixa{
      width: 92px;
      height: auto;
  }

//RESTO DE PAGINAS
.tabs-cyan {
    background-color: #067233 !important;
}
.contiene_banner{
    padding:0;
    background-color: #efefef;
    padding-top: 100px;
}
.signo-mas{
    cursor: pointer;
}
.signo-mas .link{
    color:#067233;
    text-decoration: underline;
}
.peque{
    font-size: 12px;
}
.gris{
    background-color: #efefef;
}

.bg_amarillo{
    background-color: $primary-color;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gris_claro{
    background-color: #f6f6f6;
}
.lineas_amarillas{
    background: url(../img/lineas_azules.png) #f6f6f6 no-repeat bottom right;
}
.linea{
    width: 1px;
    height: 100%;
    background-color: #D4D4D4;
    margin:0 auto;
}
.separacion{
    padding-top:80px;
}
.separador{
    padding-bottom: 2rem;
    padding-top: 2rem;
}
.linea_horizontal{
    height: 1px;
    width: 100%;
    background-color: #acacac;
}
.blanco_gris{
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(246,246,246,1) 35%, rgba(246,246,246,1) 100%);
}
.volver_principal{
    font-size: 12px;
    color: $secondary-color;
    text-decoration: none;
}
a:hover.volver_principal{
    font-size: 12px;
    color: $secondary-color;
    text-decoration: underline;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #ACACAC;
}
.accordion>.card:not(:last-of-type) {
    border-bottom: 1px solid #ACACAC;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.card-header {
    padding: 2rem 1.25rem;
}
.card-body {
    padding: 0rem 1.25rem 2rem 1.25rem;
}
.arrow{
    background: url(../img/arrow.png) no-repeat center center;
    width: 16px;
    height: 16px;
    display: block;
    float: right;
}

@media screen and (max-width:1100px){
.navbar-expand-lg .navbar-nav .nav-item {
   font-size: 0.85rem;
}
}

@media screen and (max-width:992px){
    section{
        padding: 4rem 2rem;
    }
    section.init{
        padding: 7rem 2rem 5rem 2rem;
    }
    section.gris{
        padding: 7rem 2rem 5rem 2rem;
    }
    .blanco_gris{
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(246,246,246,1) 35%, rgba(246,246,246,1) 100%);
        padding: 7rem 2rem 5rem 2rem;
    }
}


@media screen and (max-width:767px){

    section{
        padding: 4rem 1rem;
    }
    section.init{
        padding: 6rem 1rem 4rem 1rem;
    }
    .separacion{
        padding-top:40px;
    }
    .blanco_gris{
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 22%, rgba(246,246,246,1) 22%, rgba(246,246,246,1) 100%);
        padding: 6rem 1rem 4rem 1rem;
    }
    h2, .h2 {
        font-size: 1.5rem;
    }
    .card-header {
        padding: 2rem 0rem;
    }
    .card-body {
        padding: 0rem .25rem 1.5rem .25rem;
    }
    .logo_maraton_zaragoza{
        background: url(../img/logo_maraton.svg) no-repeat center center;
        width: 277px;
        height: 35px;
    }
        
    
    
}